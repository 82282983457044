<script>
import Layout from "../../layouts/main"
import { onMounted, ref, reactive, watch } from "vue"
import { getCustomers, exportCustomersList } from "./api"
import { formatDatetimeBR } from "../../../utils/date"
import { debounce } from "lodash"
import AppPagination from "../../../components/app-pagination"
import SelectContest from '@/components/select-contest'
import { Toasts } from "../../../utils/toast-alerts"

export default {
  setup() {
    const loading = ref(false)
    const clientList = ref()
    const totalClients = ref(0)
    const page = ref(1)
    const pages = ref(0)
    const filterClient = reactive({
      search: "",
      limit: 10,
      page: 1,
    })
    const contestId = ref(null)
    const exportDisabled = ref(false)

    const handleSearch = debounce(async (e) => {
      filterClient.title = e.target.value
      await getClients()
    }, 500)

    async function getClients() {
      loading.value = true
      try {
        const response = await getCustomers({
          search: filterClient.search,
          contestId: contestId?.value?.value,
          page: page.value
        })
        clientList.value = response.data
        pages.value = response.last_page
        totalClients.value = response.total
        exportDisabled.value = !(response.data.length)

      }catch (error) {
        Toasts('error', 'Falha ao carregar os clientes')
      } finally {
        loading.value = false
      }
    }

    const exportList = () => {
      exportCustomersList({
        search: filterClient.search,
        contestId: contestId?.value?.value,
        page: page.value
      })
    }

    watch([
      contestId
    ], () => {
      if (contestId.value) {
        getClients()
      }
    })

    onMounted(() => {
      // getClients()
    })

    return {
      loading,
      clientList,
      getClients,
      formatDatetimeBR,
      exportList,
      onMounted,
      filterClient,
      handleSearch,
      page,
      pages,
      totalClients,
      contestId,
      exportDisabled
    }
  },
  components: {
    AppPagination,
    SelectContest,
    Layout,
  },
}

</script>

<template>
  <Layout :pagetitle="'Clientes'">
    <div class="card">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div>
            <h5 class="card-header bg-transparent border-bottom text-uppercase">
              Clientes <span class="text-muted fw-normal ms-2">({{ totalClients }})</span>
            </h5>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="card-header bg-transparent border-bottom d-flex flex-wrap  justify-content-end  mb-3"
          >
            <div>
              <ul class="nav nav-pills">
                <li class="nav-item">
                  <button
                    style="margin-right: 20px;"
                    class="btn btn-info waves-effect waves-light"
                    @click="exportList"
                    :disabled="exportDisabled"
                  >
                    <i class="mdi mdi-archive-arrow-down-outline"></i>
                    Exportar
                  </button>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link active"
                    to="/clients"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="List"
                    aria-label="List"
                  >
                    <i class="bx bx-list-ul"></i>
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    to="/clients-grid"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    data-bs-original-title="Grid"
                    aria-label="Grid"
                  >
                    <i class="bx bx-grid-alt"></i>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h5 class="card-header pb-3">
              <div class="position-relative">
                <i
                  class="search-icon"
                  data-eva="search-outline"
                  data-eva-height="26"
                  data-eva-width="26"
                >
                </i>
                <input
                  v-model="filterClient.search"
                  type="text"
                  style="min-width: 350px; max-width: 500px;"
                  class="form-control"
                  placeholder="Pesquisar clientes por nome ou WhatsApp"
                  @input="handleSearch"
                >
              </div>
            </h5>
          </div>
          <div class="col">
            <h5 class="card-header pb-4">
              <div class="position-relative">
                <SelectContest
                  placeholder="Pesquisar por sorteio"
                  v-model="contestId"
                />
              </div>
            </h5>
          </div>
        </div>
      </div>
      <!-- end row -->
      <div class="row">
        <div class="col-lg-12">
          <div class="">
            <div class="table-responsive">
              <table
                class="table project-list-table table-nowrap align-middle table-borderless"
              >
                <thead class="table-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Status</th>
                    <th scope="col">WhatsApp</th>
                    <th scope="col">Data de cadastro</th>
                  </tr>
                </thead>
                <tbody v-if="clientList?.length > 0">
                  <tr v-for="client in clientList" :key="client">
                    <td>
                      <a class="text-body">{{ client.name }}</a>
                    </td>
                    <td>
                      <span
                        class="badge badge-soft-danger mb-0"
                        v-if="client.blocked === true"
                        style="color: red"
                      >
                        Bloqueado
                      </span>
                      <span
                        class="badge badge-soft-success mb-0"
                        v-else
                        style="color: green"
                      >
                        Ativo
                      </span>
                    </td>
                    <td>{{ client.whatsapp }}</td>
                    <td>{{ formatDatetimeBR(client.created_at) }}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="4">
                      <div class="d-flex justify-content-center">
                        <div v-if="loading" class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <div v-else>
                          <span v-if="contestId" colspan="4">
                            Nenhum cliente encontrado.
                          </span>
                          <span v-else colspan="4">
                            Selecione um sorteio para realizar a busca de clientes
                          </span>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <app-pagination
                class="pagination"
                v-model="page"
                :pages="pages"
                :range-size="1"
                @update:modelValue="getClients"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>